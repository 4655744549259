import {
  ArrayField,
  BooleanInput,
  BulkDeleteButton,
  BulkExportButton,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  List,
  SelectInput,
  SingleFieldList,
  TextField,
  TopToolbar,
  useDataProvider,
  useListContext,
  usePermissions,
} from 'react-admin'

import { Box } from '@mui/material'
import { FolderArrowDownIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import AmountField from '../components/AmountField'
import { Title } from '../components/Title'
import ConfirmVendorButton from './ConfirmVendorButton'
import MigrateButton from './MigrateButton'
import VendorMatchFlags from './VendorMatchFlags'
import ReferenceVendorMapper from './ReferenceVendorMapper'
import AssignGroupButton from './AssignGroupButton'
import userPermissions from '../Users/userPermissions'
import { getLabelSuffix } from '../helpers'
import { DateRangeFilterContext } from '../contexts/DateRangeFilterContext'
import { useQueryFilter } from '../helpers/useQueryFilter'
/*
 * The imports below are all for Date picker / filter functionality
 */
// import { useEffect, useMemo } from 'react'
// import { useQuery } from 'react-query'
// import { useDateRangeFilter } from '../helpers/useDateRangeFilter'
// import { useQueryFilter } from '../helpers/useQueryFilter'
// import { addDateRangeToQuery } from '../helpers'
// import { QueryBuilderModal } from '../components/QueryBuilderModal'
// import { InvoiceDateFilterModal } from '../components/InvoiceDateFilterModal'
// import useChartContext from '../Dashboard/charts/useChartContext'

export const ops = [
  { name: 'in', label: 'in' },
  { name: 'notin', label: 'not in' },
  // { name: '=', label: 'is' },
  // { name: '!=', label: 'is not' },
]

export const vendorOps = [
  { name: 'in', label: 'in' },
  { name: 'notin', label: 'not in' },
]

const ExportCsvButton = () => {
  const { filterValues } = useListContext()

  const dp = useDataProvider()

  const downloadFile = (token) => {
    dp.getCSVUrl(filterValues, token).then((url) => {
      window.location = url
    })
  }

  const handleDownload = () => {
    dp.getCSVRowCount(filterValues).then((data) => {
      // magic number for rows
      const confirmLimit = 1048576
      if (data.data.count >= confirmLimit) {
        if (
          window.confirm(
            'This export will exceed 1,048,576 rows. Are you sure you wish to continue?'
          )
        ) {
          downloadFile(data.data.token)
        }
      } else {
        downloadFile(data.data.token)
      }
    })
  }

  return (
    <button
      type="button"
      className="py-2 border border-green-800 p-3 active rounded bg-green-700 text-white font-medium text-sm inline-flex space-x-2 items-center hover:bg-opacity-90"
      color="primary"
      onClick={handleDownload}
    >
      <FolderArrowDownIcon className="h-4" />
      <span>Download to Excel</span>
    </button>
  )
}

/*
 * Below and commented out is everything for putting the Date picker and the query filter onto the transactions page.
 * The Date picker is buggy as hell and interferes with the Dashboard date picker. Because we want to
 * filter transactions for a specific invoice date as well as the query, until the date picker is fixed
 * the back end is handling the filtering via capturing and caching the last choice the user made on the
 * dashboard and applying it to the transaction filter at the back end instead.
 * Once the Date picker is fixed, that functionality can be deprecated in the back end, however if the app
 * provides the "query" querystring parameter, the backend functionality is skipped and won't interfere
 */
// const TransactionFilterForm = () => {
//   const { filterValues, setFilters } = useListContext()
//   const { dateRange } = useChartContext()
//   const dp = useDataProvider()
//   const queryFilter = useQueryFilter()
//   const dateRangeFilter = useDateRangeFilter()
//
//   const formattedQuery = useMemo(() => {
//     return JSON.stringify(
//       addDateRangeToQuery(
//         queryFilter.currentQueryRaw,
//         dateRangeFilter.ranges
//           ? dateRangeFilter.ranges[0].startDate
//           : dateRange.startDate,
//         dateRangeFilter.ranges
//           ? dateRangeFilter.ranges[0].endDate
//           : dateRange.endDate
//       )
//     )
//   }, [queryFilter.currentQueryRaw, dateRangeFilter.ranges])
//
//   useEffect(() => {
//     if (filterValues.query !== formattedQuery) {
//       setFilters({ query: formattedQuery }, [])
//     }
//   }, [formattedQuery, filterValues.query, setFilters])
//
//   const { data: tagCategoriesData, isLoading: tagCategoriesLoading } = useQuery(
//     `tagCategoriesData`,
//     () => dp.getFilterFields()
//   )
//   const defaultRange = [
//     {
//       startDate: dateRange.startDate,
//       endDate: dateRange.endDate,
//       key: dateRange.key,
//     },
//   ]
//   return tagCategoriesLoading && !tagCategoriesData ? (
//     <>Loading...</>
//   ) : (
//     <div className="flex items-center gap-3">
//       <InvoiceDateFilterModal
//         defaultRange={defaultRange}
//         ranges={defaultRange}
//         setRanges={dateRangeFilter.setRanges}
//         compareWithEnabled={false}
//       />
//       <QueryBuilderModal
//         queryFilter={queryFilter}
//         fields={tagCategoriesData.data}
//       />
//     </div>
//   )
// }
//
// const ListActions = () => (
//   <Box width="100%">
//     <TopToolbar>
//       <TransactionFilterForm />
//       <ExportCsvButton />
//     </TopToolbar>
//   </Box>
// )

const BulkActionList = () => {
  const { permissions } = usePermissions()
  const { selectedIds } = useListContext()

  if (selectedIds?.length < 1) return null
  if (!(permissions & userPermissions.EDIT_TRANSACTIONS)) return null

  return (
    <div className="relative">
      <div>
        <div className="bg-gray-100 text-xs font-medium py-1.5 px-3 rounded-t border border-b-0 inline-block">
          Bulk edit
        </div>
      </div>
      <div className="flex items-center gap-2.5 fade-in-up py-1 px-2 rounded-md rounded-tl-none border bg-white">
        <BulkDeleteButton mutationMode="pessimistic" />
        <BulkExportButton />
        <ConfirmVendorButton />
      </div>
    </div>
  )
}

const ListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <BulkActionList />
      <ExportCsvButton />
    </TopToolbar>
  </Box>
)

const TransactionList = () => {
  const { permissions } = usePermissions()
  const { t } = useTranslation()
  const suffix = getLabelSuffix()

  const dateRangeFilter = useContext(DateRangeFilterContext)
  const { currentQuery } = useQueryFilter()

  const TransactionFilters = [
    <SelectInput
      sx={{ minWidth: 300 }}
      label={t(`vendor_match_flags${suffix}`)}
      source="vendor_match_flags"
      choices={VendorMatchFlags}
      emptyText="Show all"
      alwaysOn
    />,
    <BooleanInput
      label={t(`uncategorised_transactions${suffix}`)}
      source="untagged"
      alwaysOn
    />,
  ]

  return (
    <section className="space-y-6 w-full">
      <Title>{t(`transactions${suffix}`)}</Title>
      <List
        className="w-full"
        sort={{ field: 'invoice_date', order: 'DESC' }}
        actions={<ListActions />}
        filters={TransactionFilters}
        filter={{
          startDate: dateRangeFilter.ranges?.[0]?.startDate,
          endDate: dateRangeFilter.ranges?.[0]?.endDate,
          query: JSON.stringify(currentQuery),
        }}
      >
        <Datagrid className="w-full">
          <TextField source="txn_id" label="Tx Number" />
          <TextField source="invoice_vendor_name" label="Vendor" />
          <AmountField label="Amount" />
          <DateField source="invoice_date" />
          <ArrayField source="tags" sortable={false}>
            <SingleFieldList className="flex">
              <ChipField
                source="name"
                className="!max-w-[400px] text-ellipsis"
              />
            </SingleFieldList>
          </ArrayField>
          {permissions & userPermissions.SUPER_ADMIN && (
            <ReferenceVendorMapper />
          )}
          {permissions & userPermissions.EDIT_TRANSACTIONS && (
            <AssignGroupButton />
          )}
          {permissions & userPermissions.EDIT_TRANSACTIONS && <MigrateButton />}
          {permissions & userPermissions.EDIT_TRANSACTIONS && (
            <EditButton label="" />
          )}
        </Datagrid>
      </List>
    </section>
  )
}

export default TransactionList
